<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm bcs-user-info-card">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable&&!disabled&&isOld" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="survey"
            mappingType="PUT"
            label="업무적합성 평가 실시" 
            icon="check"
            @beforeAction="completeSurvey"
            @btnCallback="completeSurveyCallback" />
        </q-btn-group>
      </template>
      <template slot="card-title-custom">
        <div class="col" style="text-align: center;">
          <q-chip dense color="indigo" text-color="white" icon="bookmark" outline square>
            질병관리본부에서 서울대학교 산학협력단과 연구한 결과를 토대로 구성되었습니다.
          </q-chip>
        </div>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 bcs-user-info">
          근로자 : (만 {{survey.age}}세 {{survey.sexName}}) {{survey.deptName}} / {{survey.userName}}<br/>
          조사일 : {{survey.surveyDate}}
        </div>
      </template>
    </c-card>
    <c-card class="cardClassDetailForm bcs-risk-assess-card" :isTitle="false">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div class="widget-chart container-counter">
            <div class="widget-subheading">
              나의 심뇌혈관질환 발생 위험<br/>
              ({{survey.age}})세 {{survey.sexName}} 평균 대비
            </div>
            <div class="widget-numbers cursor-help">
              <span class="big-counter">
                {{survey.riskAssessment.adjustedCompositeRelativeRisk}}
              </span>
              <span class="big-counter-description">
                배
              </span>
              <q-tooltip content-class="bg-amber text-black" content-style="font-size: 16px; font-weight:600;" :offset="[10, 10]">
                <q-icon name="info" />
                (개인별 복합 상대위험도) / (나이, 성별에 따른 평균 복합 상대위험도)
              </q-tooltip>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <apexchart 
            ref="chart" 
            height="130" 
            type="bar"
            :options="chart.chartOptions" 
            :series="chart.series"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div class="widget-chart container-counter">
            <div class="widget-subheading">
              심뇌혈관 나이<br/>
            </div>
            <div class="widget-numbers cursor-help">
              <span class="big-counter">
                {{survey.riskAssessment.cardiovascularAge}}
              </span>
              <span class="big-counter-description">
                세
              </span>
            </div>
            <q-tooltip content-class="bg-amber text-black" content-style="font-size: 16px; font-weight:600;" :offset="[10, 10]">
              <q-icon name="info" />
              절대위험도의 값이 평균 절대위험도와 가장 가까운 나이
            </q-tooltip>
          </div>
        </div>
      </template>
    </c-card>
    <c-table
      title="나의 건강위험요인 알아보기"
      :columns="grid.columns"
      :data="gridData"
      gridHeightAuto
      hideBottom
      isDense
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
    </c-table>
    <c-card class="cardClassDetailForm bcs-risk-assess-card" :isTitle="false">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="widget-chart container-counter no-border">
            <div class="widget-subheading">
              향후 10년 이내에 심뇌혈관질환이 발생할 확률 교정
            </div>
            <div class="widget-numbers">
              <span class="cursor-help">
                <span class="middle-counter">
                  {{survey.riskAssessment.reducedRiskDevelopDisease}}
                </span>
                <span class="middle-counter-description">
                  % 감소
                </span>
                <q-tooltip content-class="bg-amber text-black" content-style="font-size: 16px; font-weight:600;" :offset="[10, 10]">
                  <q-icon name="info" />
                  (발병위험도 - 교정 절대위험도) * 100 / 발병위험도
                </q-tooltip>
              </span>
              <span class="cursor-help">
                <span class="small-counter">
                  ({{survey.riskAssessment.riskDevelopDisease}}
                </span>
                <span class="small-counter-description">
                  %
                </span>
                <q-tooltip content-class="bg-amber text-black" content-style="font-size: 16px; font-weight:600;" :offset="[10, 10]">
                  <q-icon name="info" />
                  (개인별 복합 상대위험도) / (나이, 성별에 따른 평균 복합 상대위험도) x (나이, 성별에 따른 평균 절대위험도) / (100000, 10만 명 당) * 100
                </q-tooltip>
              </span>
              <q-icon
                size="md"
                name="arrow_forward"
              />
              <span class="cursor-help">
                <span class="small-counter">
                  {{survey.riskAssessment.riskOrthodonticDevelopment}}
                </span>
                <span class="small-counter-description">
                  %
                </span>
                <span class="small-counter">
                  )
                </span>
                <q-tooltip content-class="bg-amber text-black" content-style="font-size: 16px; font-weight:600;" :offset="[10, 10]">
                  <q-icon name="info" />
                  (교정 복합 상대위험도) / (나이, 성별에 따른 평균 복합 상대위험도) x (나이, 성별에 따른 평균 절대위험도) / (100000, 10만 명 당) * 100
                </q-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <q-separator vertical inset />
          <div class="widget-chart container-counter no-border">
            <div class="widget-subheading">
              심뇌혈관 나이<br/>
            </div>
            <div class="widget-numbers">
              <span class="cursor-help">
                <span class="middle-counter">
                  {{survey.riskAssessment.cardiovascularAge}}
                </span>
                <span class="middle-counter-description">
                  세
                </span>
                <q-tooltip content-class="bg-amber text-black" content-style="font-size: 16px; font-weight:600;" :offset="[10, 10]">
                  <q-icon name="info" />
                  절대위험도의 값이 평균 절대위험도와 가장 가까운 나이
                </q-tooltip>
              </span>
              <q-icon
                size="md"
                name="arrow_forward"
              />
              <span class="cursor-help">
                <span class="middle-counter">
                  {{survey.riskAssessment.correctedCardiovascularAge}}
                </span>
                <span class="middle-counter-description">
                  세
                </span>
                <q-tooltip content-class="bg-amber text-black" content-style="font-size: 16px; font-weight:600;" :offset="[10, 10]">
                  <q-icon name="info" />
                  교정 절대위험도의 값이 평균 절대위험도와 가장 가까운 나이
                </q-tooltip>
              </span>
            </div>
          </div>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'brainCardiovascularRiskAssessment',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',
      }),
    },
    survey: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 종합조사표 일련번호
        heaBrainCardiovascularStepCd: '',  // 진행상태
        userId: '',  // 근로자ID
        userName: '',  // 근로자명
        deptCd: '',  // 부서코드
        deptName: '',  // 부서명
        age: 0,  // 나이
        sexCd: '',  // 성별
        sexName: '',  // 성별
        birthDate: '', // 생년월일
        surveyDate: '',  // 평가일
        waistCircumference: '',  // 위험도_허리둘레
        bmi: '',  // 위험도_체질량 지수
        bloodPressure: '',  // 위험도_혈압
        hypertensionMedication: '',  // 위험도_고혈압 약 복용
        fastingBloodSugar: '',  // 위험도_공복 혈당
        diabetesMedication: '',  // 위험도_당뇨병 약 복용
        totalCholesterol: '',  // 위험도_총콜레스테롤
        egfr: '',  // 위험도_eGFR
        dipstickProteinuria: '',  // 위험도_Dipstick proteinuria 단백뇨
        smoking: '',  // 위험도_흡연상태
        physicallyActiveStatus: '',  // 위험도_신체 활동 여부
        regUserId: '',  //
        chgUserId: '',  //
        results: [], // 유해요인 평가결과 목록
        riskAssessment: {
          heaBrainCardiovascularRiskAssessmentId: '',  // 뇌심혈관계 위험평가 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          adjustedCompositeRelativeRisk: '',  // 조정 복합 상대위험도
          riskDevelopDisease: '',  // 발병위험도(10년)
          avgRiskDevelopDisease: '',  // 평균발병위험도(10년)
          riskOrthodonticDevelopment: '',  // 교정발병위험도(10년)
          reducedRiskDevelopDisease: '',  // 발병위험도 감소치
          cardiovascularAge: '',  // 심뇌혈관 나이
          correctedCardiovascularAge: '',  // 교정 심뇌혈관 나이
          integratedRiskClassificationCriteria: '',  // 통합형 위험도 분류기준
          /** 기타 정보 */
          complexRelativeRisk: '',  // 개인별 복합 상대위험도
          correctionCompositeRelativeRisk: '',  // 개인별 교정 복합 상대위험도
          regUserId: '',  //
          chgUserId: '',  //
        }, // 위험평가
        suitEval: {
          heaBrainCardiovascularWorkSuitEvalId: '',  // 뇌심혈관계 업무적합성 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          userId: '',  // 근로자ID (종합조사표 값)
          userName: '',  // 근로자명 (종합조사표 값)
          deptCd: '',  // 부서코드 (종합조사표 값)
          deptName: '',  // 부서명 (종합조사표 값)
          tasksPerformed: '',  // 수행업무
          diseaseReview: '',  // 임상증상 / 동반질환 검토의견
          habitsReview: '',  // 생활습관 / 검사항목 검토의견
          businessFactorsReview: '',  // 업무적요인 검토의견
          generalOpinionCd: '',  // 종합의견 - 결과
          generalOpinion: '',  // 종합의견 - 적합 이외인 경우 기술
          heaManagerId: '',  // 보건담당자 OR 의사
          regUserId: '',  //
          chgUserId: '',  //
        }, // 업무적합성 평가서
      }),
    },
    evalItems: {
      type: Array,
      default: () => [],
    },
    evalItemClasses: {
      type: Array,
      default: () => [],
    },
    riskPredictions: {
      type: Array,
      default: () => [],
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'riskFactors',
            field: 'riskFactors',
            label: '건강위험요인',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'riskFactorsResultView',
            field: 'riskFactorsResultView',
            label: '현재상태',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'riskFactorsGoalResult',
            field: 'riskFactorsGoalResult',
            label: '목표상태',
            align: 'left',
            sortable: false,
            style: 'width:250px',
          },
          {
            name: 'healthConditionName',
            field: 'healthConditionName',
            label: '건강신호등',
            align: 'center',
            sortable: false,
            style: 'width:130px',
          },
        ],
      },
      editable: true,
      isComplete: true,
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.isOld && this.survey.heaBrainCardiovascularStepCd !== 'HBCS000005'
    },
    sexAge() {
      return `${this.survey.age} / ${this.survey.sexName}`
    },
    chart() {
      return {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '향후 10년 이내에 심뇌혈관질환이 발생할 확률'
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px',
              colors: ['#fff']
            }
          },
          xaxis: {
            categories: [
              `${this.survey.userName} 님`,
              `${this.survey.age}세 ${this.survey.sexName} 평균`
            ],
          },
        },
        series: [{
          data: [
            this.survey.riskAssessment.riskDevelopDisease, 
            this.survey.riskAssessment.avgRiskDevelopDisease
          ]
        }],
        chartWidth: '80%',
      }
    },
    gridData() {
      let data = [];
      let evalItems = this.$_.filter(this.evalItems, { riskAssessFlag: 'Y' })
      if (evalItems && evalItems.length > 0) {
        this.$_.forEach(evalItems, evalItem => {
          let checkData = this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: evalItem.heaBrainCardiovascularEvalItemId})
          if (checkData) {
            data.push(checkData)
          }
        })
      }
      return data;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.completeUrl = transactionConfig.hea.bcs.workSuitEval.step.url
      // code setting
      // list setting
    },
    completeSurvey() {      
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '업무적합성 평가 실시하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.survey.chgUserId = this.$store.getters.user.userId

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
  }
};
</script>
<style lang="sass">
.bcs-user-info-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important
.bcs-user-info
  font-size: 1.2rem
  text-align: center
  font-weight: 600

.bcs-risk-assess-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important
.container-counter
  border-left: 1px solid rgba(0,0,0,0.12)
  border-right: 1px solid rgba(0,0,0,0.12)
  .widget-numbers
    color: #e75404
  .big-counter
    font-size: 2.5rem
  .big-counter-description
    font-size: 2rem
  .middle-counter
    font-size: 2rem
  .middle-counter-description
    font-size: 1.3rem
  .small-counter
    font-size: 1.5rem
  .small-counter-description
    font-size: 1.1rem
</style>