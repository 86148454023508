var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm bcs-user-info-card" },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && !_vm.disabled && _vm.isOld,
                        expression: "editable&&!disabled&&isOld",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isComplete,
                      url: _vm.completeUrl,
                      param: _vm.survey,
                      mappingType: "PUT",
                      label: "업무적합성 평가 실시",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.completeSurvey,
                      btnCallback: _vm.completeSurveyCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-title-custom" }, [
            _c(
              "div",
              { staticClass: "col", staticStyle: { "text-align": "center" } },
              [
                _c(
                  "q-chip",
                  {
                    attrs: {
                      dense: "",
                      color: "indigo",
                      "text-color": "white",
                      icon: "bookmark",
                      outline: "",
                      square: "",
                    },
                  },
                  [
                    _vm._v(
                      " 질병관리본부에서 서울대학교 산학협력단과 연구한 결과를 토대로 구성되었습니다. "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 bcs-user-info",
              },
              [
                _vm._v(
                  " 근로자 : (만 " +
                    _vm._s(_vm.survey.age) +
                    "세 " +
                    _vm._s(_vm.survey.sexName) +
                    ") " +
                    _vm._s(_vm.survey.deptName) +
                    " / " +
                    _vm._s(_vm.survey.userName)
                ),
                _c("br"),
                _vm._v(" 조사일 : " + _vm._s(_vm.survey.surveyDate) + " "),
              ]
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm bcs-risk-assess-card",
          attrs: { isTitle: false },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
              [
                _c("div", { staticClass: "widget-chart container-counter" }, [
                  _c("div", { staticClass: "widget-subheading" }, [
                    _vm._v(" 나의 심뇌혈관질환 발생 위험"),
                    _c("br"),
                    _vm._v(
                      " (" +
                        _vm._s(_vm.survey.age) +
                        ")세 " +
                        _vm._s(_vm.survey.sexName) +
                        " 평균 대비 "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "widget-numbers cursor-help" },
                    [
                      _c("span", { staticClass: "big-counter" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.survey.riskAssessment
                                .adjustedCompositeRelativeRisk
                            ) +
                            " "
                        ),
                      ]),
                      _c("span", { staticClass: "big-counter-description" }, [
                        _vm._v(" 배 "),
                      ]),
                      _c(
                        "q-tooltip",
                        {
                          attrs: {
                            "content-class": "bg-amber text-black",
                            "content-style":
                              "font-size: 16px; font-weight:600;",
                            offset: [10, 10],
                          },
                        },
                        [
                          _c("q-icon", { attrs: { name: "info" } }),
                          _vm._v(
                            " (개인별 복합 상대위험도) / (나이, 성별에 따른 평균 복합 상대위험도) "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("apexchart", {
                  ref: "chart",
                  attrs: {
                    height: "130",
                    type: "bar",
                    options: _vm.chart.chartOptions,
                    series: _vm.chart.series,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
              [
                _c(
                  "div",
                  { staticClass: "widget-chart container-counter" },
                  [
                    _c("div", { staticClass: "widget-subheading" }, [
                      _vm._v(" 심뇌혈관 나이"),
                      _c("br"),
                    ]),
                    _c("div", { staticClass: "widget-numbers cursor-help" }, [
                      _c("span", { staticClass: "big-counter" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.survey.riskAssessment.cardiovascularAge
                            ) +
                            " "
                        ),
                      ]),
                      _c("span", { staticClass: "big-counter-description" }, [
                        _vm._v(" 세 "),
                      ]),
                    ]),
                    _c(
                      "q-tooltip",
                      {
                        attrs: {
                          "content-class": "bg-amber text-black",
                          "content-style": "font-size: 16px; font-weight:600;",
                          offset: [10, 10],
                        },
                      },
                      [
                        _c("q-icon", { attrs: { name: "info" } }),
                        _vm._v(
                          " 절대위험도의 값이 평균 절대위험도와 가장 가까운 나이 "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        attrs: {
          title: "나의 건강위험요인 알아보기",
          columns: _vm.grid.columns,
          data: _vm.gridData,
          gridHeightAuto: "",
          hideBottom: "",
          isDense: "",
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
      }),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm bcs-risk-assess-card",
          attrs: { isTitle: false },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c(
                  "div",
                  { staticClass: "widget-chart container-counter no-border" },
                  [
                    _c("div", { staticClass: "widget-subheading" }, [
                      _vm._v(
                        " 향후 10년 이내에 심뇌혈관질환이 발생할 확률 교정 "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "widget-numbers" },
                      [
                        _c(
                          "span",
                          { staticClass: "cursor-help" },
                          [
                            _c("span", { staticClass: "middle-counter" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.survey.riskAssessment
                                      .reducedRiskDevelopDisease
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "middle-counter-description" },
                              [_vm._v(" % 감소 ")]
                            ),
                            _c(
                              "q-tooltip",
                              {
                                attrs: {
                                  "content-class": "bg-amber text-black",
                                  "content-style":
                                    "font-size: 16px; font-weight:600;",
                                  offset: [10, 10],
                                },
                              },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(
                                  " (발병위험도 - 교정 절대위험도) * 100 / 발병위험도 "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "cursor-help" },
                          [
                            _c("span", { staticClass: "small-counter" }, [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm.survey.riskAssessment.riskDevelopDisease
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "small-counter-description" },
                              [_vm._v(" % ")]
                            ),
                            _c(
                              "q-tooltip",
                              {
                                attrs: {
                                  "content-class": "bg-amber text-black",
                                  "content-style":
                                    "font-size: 16px; font-weight:600;",
                                  offset: [10, 10],
                                },
                              },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(
                                  " (개인별 복합 상대위험도) / (나이, 성별에 따른 평균 복합 상대위험도) x (나이, 성별에 따른 평균 절대위험도) / (100000, 10만 명 당) * 100 "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("q-icon", {
                          attrs: { size: "md", name: "arrow_forward" },
                        }),
                        _c(
                          "span",
                          { staticClass: "cursor-help" },
                          [
                            _c("span", { staticClass: "small-counter" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.survey.riskAssessment
                                      .riskOrthodonticDevelopment
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "small-counter-description" },
                              [_vm._v(" % ")]
                            ),
                            _c("span", { staticClass: "small-counter" }, [
                              _vm._v(" ) "),
                            ]),
                            _c(
                              "q-tooltip",
                              {
                                attrs: {
                                  "content-class": "bg-amber text-black",
                                  "content-style":
                                    "font-size: 16px; font-weight:600;",
                                  offset: [10, 10],
                                },
                              },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(
                                  " (교정 복합 상대위험도) / (나이, 성별에 따른 평균 복합 상대위험도) x (나이, 성별에 따른 평균 절대위험도) / (100000, 10만 명 당) * 100 "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("q-separator", { attrs: { vertical: "", inset: "" } }),
                _c(
                  "div",
                  { staticClass: "widget-chart container-counter no-border" },
                  [
                    _c("div", { staticClass: "widget-subheading" }, [
                      _vm._v(" 심뇌혈관 나이"),
                      _c("br"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "widget-numbers" },
                      [
                        _c(
                          "span",
                          { staticClass: "cursor-help" },
                          [
                            _c("span", { staticClass: "middle-counter" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.survey.riskAssessment.cardiovascularAge
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "middle-counter-description" },
                              [_vm._v(" 세 ")]
                            ),
                            _c(
                              "q-tooltip",
                              {
                                attrs: {
                                  "content-class": "bg-amber text-black",
                                  "content-style":
                                    "font-size: 16px; font-weight:600;",
                                  offset: [10, 10],
                                },
                              },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(
                                  " 절대위험도의 값이 평균 절대위험도와 가장 가까운 나이 "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("q-icon", {
                          attrs: { size: "md", name: "arrow_forward" },
                        }),
                        _c(
                          "span",
                          { staticClass: "cursor-help" },
                          [
                            _c("span", { staticClass: "middle-counter" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.survey.riskAssessment
                                      .correctedCardiovascularAge
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "middle-counter-description" },
                              [_vm._v(" 세 ")]
                            ),
                            _c(
                              "q-tooltip",
                              {
                                attrs: {
                                  "content-class": "bg-amber text-black",
                                  "content-style":
                                    "font-size: 16px; font-weight:600;",
                                  offset: [10, 10],
                                },
                              },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(
                                  " 교정 절대위험도의 값이 평균 절대위험도와 가장 가까운 나이 "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }